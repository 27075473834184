import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const configSlice = createSlice({
  name: "config",
  initialState: {
    config: {} as any,
  },
  reducers: {
    updateConfig: (state, action: PayloadAction<any>) => {
      state.config = action.payload;
    },
  },
});

export const { updateConfig } = configSlice.actions;

export default configSlice.reducer;
