import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const ContactInfo = () => {
  const { t } = useTranslation();
  const { config } = useSelector((state: any) => state.config);
  const isConfigLoaded = Object.keys(config).length !== 0;
  let contactDetails = null;

  if (isConfigLoaded) {
    contactDetails = config.config_list.find(
      (i: any) => i.config_key === "contact_details",
    );
  }

  return (
    <section data-testid="contact-info-section">
      <div className="flex flex-col justify-between gap-3 rounded-lg border bg-primary-100 px-16 py-8 lg:flex-row max-lg:px-6">
        <div data-testid="contact-title">
          <h2 className="mb-1 text-xl">
            {t("contact.OUR_CONTACT_INFO_TITLE")}
          </h2>
          <p className="max-w-[25ch] text-sm text-background-grey-800">
            {t("contact.OUR_CONTACT_INFO_DESCRIPTION")}
          </p>
        </div>
        <div className="flex flex-col" data-testid="office-address">
          <h3 className="mb-2 text-wrap text-base">
            {t("contact.OFFICE_ADDRESS")}
          </h3>
          <p className="break-words text-sm text-background-grey-800 sm:max-w-lg md:max-w-md lg:max-w-xs">
            {contactDetails?.config_value?.office_address}
          </p>
        </div>

        <div className="flex flex-col" data-testid="registered-address">
          <h3 className="mb-2 text-base">{t("contact.REGISTERED_ADDRESS")}</h3>
          <p className="break-words text-sm text-background-grey-800 sm:max-w-lg md:max-w-md lg:max-w-xs">
            {contactDetails?.config_value?.registered_address}
          </p>
        </div>

        <div className="flex flex-col" data-testid="contact-details">
          <h3 className="mb-2 text-base">{t("contact.CONTACT_DETAILS")}</h3>
          <p className="text-sm text-background-grey-800">
            <span data-testid="fax">
              Fax: {contactDetails?.config_value?.fax}
            </span>
            <br />
            <span data-testid="phone">
              Phone: {contactDetails?.config_value?.phone}
            </span>
            <br />
            <span data-testid="email">
              Email: {contactDetails?.config_value?.email}
            </span>
          </p>
        </div>
      </div>
    </section>
  );
};

export default ContactInfo;
