import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    cartLength: 0 as number | null,
  },
  reducers: {
    updateCartLength: (
      state,
      action: PayloadAction<{ length: number | null }>,
    ) => {
      state.cartLength = action.payload.length;
    },
  },
});

export const { updateCartLength } = cartSlice.actions;

export default cartSlice.reducer;
