import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import apiService from "../../../services/apiService";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import { useTranslation } from "react-i18next";
import { MinusIcon, PlusIcon } from "@heroicons/react/24/outline";

function QuickSearchAddItem({ setQuickAdd }: any) {
  const [searchData, setSearchData] = useState([]);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<any>(null);
  const [quantity, setQuantity] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const {
    register,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      searchTerm: "",
      quantity: 0,
    },
  });

  useEffect(() => {
    const debounceId = setTimeout(() => {
      if (debouncedSearchTerm) {
        handelInputChange(debouncedSearchTerm);
      }
    }, 1000);

    return () => {
      clearTimeout(debounceId);
    };
  }, [debouncedSearchTerm]);

  const handelInputChange = async (value: string) => {
    const res = await apiService.searchProductSizes(value);
    if (res?.data?.products) {
      setSearchData(res.data.products);
      setShowDropdown(true);
    } else {
      setShowDropdown(false);
    }
  };

  const handleSelectProduct = (product: any) => {
    setValue("searchTerm", product.name);
    setSelectedProduct(product);
    setQuantity(0);
    setShowDropdown(false);
  };

  const handelQuantityChanges = (value: any) => {
    const qty = Number(value);
    if (selectedProduct) {
      setQuantity(qty);
    }
    setShowDropdown(false);
  };

  const incrementQuantity = () => {
    if (selectedProduct) {
      handelQuantityChanges(quantity + (selectedProduct.order_quantity || 1));
    }
  };

  const decrementQuantity = () => {
    if (selectedProduct) {
      handelQuantityChanges(
        Math.max(
          quantity - (selectedProduct.order_quantity || 1),
          selectedProduct.order_quantity || 1,
        ),
      );
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (selectedProduct === null || quantity === 0)
      return window.alert("NO Product/Product Quantity Selected");

    const { name, product_id } = selectedProduct;
    const payload = { product_name: name, product_id, quantity };
    setIsLoading(true);
    setShowDropdown(false);
    try {
      await apiService.addToCart([payload]);
      toast.success("Product added to Cart");
      setQuickAdd((prev: any) => !prev);
    } catch (error) {
      toast.error("Failed to add Product to Cart");
    } finally {
      setValue("searchTerm", "");
      setQuantity(0);
    }
  };

  return (
    <form
      onSubmit={(e) => handleSubmit(e)}
      className="flex w-full items-center justify-between gap-6 rounded-sm border border-primary-400 bg-primary/20 p-4 max-lg:flex-col max-lg:items-start max-lg:gap-1"
    >
      <h5 className="text-lg font-semibold">{t("cart.QUICK_SEARCH")}</h5>
      <div className="relative mt-2 flex w-full flex-grow items-center gap-3 max-sm:flex-col">
        <div className="relative w-full">
          <input
            {...register("searchTerm")}
            type="search"
            placeholder="Search by product, cat. number..."
            className="rounded border px-4"
            pattern="^[a-zA-Z0-9()∅. -]*$"
            onChange={(e) => {
              const input = e.target;
              const value = input.value;
              input.setCustomValidity("");

              const isValid = new RegExp(input.pattern).test(value);

              if (isValid) {
                setDebouncedSearchTerm(value);
              } else {
                input.setCustomValidity(
                  "Invalid input: only alphanumeric characters, spaces, and certain symbols are allowed.",
                );
                input.reportValidity();
              }
            }}
            autoComplete="off"
          />

          {errors.searchTerm?.message && (
            <p className="m-0 p-0 text-red-600">{errors.searchTerm?.message}</p>
          )}
          {/* Dropdown for search results */}
          {showDropdown && searchData.length > 0 && (
            <ul className="absolute z-10 mt-1 max-h-48 w-full overflow-y-auto rounded border border-gray-300 bg-white shadow-lg">
              {searchData.map((item: any, index) => (
                <li
                  key={index}
                  className="cursor-pointer px-4 py-2 hover:bg-gray-100"
                  onClick={() => handleSelectProduct(item)}
                >
                  {item?.name} {item?.catalogue_no}
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="flex gap-3 max-sm:w-full max-sm:justify-between">
          <div className="relative">
            <div className="flex items-center rounded-s bg-white px-1">
              <button type="button" onClick={decrementQuantity}>
                <MinusIcon width={15} height={15} />
              </button>
              <input
                {...register("quantity", { valueAsNumber: true })}
                onChange={(e) => {
                  const input = e.target;
                  input.setCustomValidity("");
                  const isValid = input.reportValidity();
                  if (!isValid) {
                    input.setCustomValidity(
                      `Please enter a valid quantity in multiple of ${selectedProduct.order_quantity}`,
                    );
                    input.title = input.validationMessage;
                  }
                  handelQuantityChanges(e.target.value);
                }}
                type="number"
                placeholder="QTY."
                className="no-spinner-input w-24 rounded border-none bg-white px-4 text-center"
                value={quantity}
                min={selectedProduct?.order_quantity}
                step={selectedProduct?.order_quantity}
                autoComplete="off"
              />
              <button type="button" onClick={incrementQuantity}>
                <PlusIcon width={15} height={15} />
              </button>
            </div>
            {errors.quantity && (
              <p className="text-sm text-red-500">{errors.quantity.message}</p>
            )}
          </div>
          <button
            type="submit"
            disabled={isLoading}
            className="rounded bg-blue-500 bg-primary px-8 py-3 text-white"
          >
            {isLoading ? (
              <ClipLoader size={20} color="#69b3f4"></ClipLoader>
            ) : (
              t("common.ADD")
            )}
          </button>
        </div>
      </div>
    </form>
  );
}

export default QuickSearchAddItem;
