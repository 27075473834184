import { useTranslation } from "react-i18next";

export default function HeroBanner({
  productTitle,
  title,
  onePlace,
  description,
}: any) {
  const { t } = useTranslation();

  return (
    <div className="gradient flex w-full flex-col items-start justify-center p-12 text-white lg:w-1/2">
      <h3 className="absolute start-12 top-10 mt-12 text-3xl font-bold">
        {productTitle || t("common.PRODUCT_TITLE")}
      </h3>
      <h1 className="mb-6 pt-24 text-4xl font-bold lg:pt-0">
        {title || t("sign_in.TITLE")}{" "}
        <span className="heading italic">
          {onePlace || t("sign_in.ONE_PLACE")}
        </span>
      </h1>
      <p className="mb-6 text-lg">{description || t("sign_in.DESCRIPTION")}</p>
    </div>
  );
}
