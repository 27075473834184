import { ClipLoader } from "react-spinners";

interface DialogueBoxProps {
  icon: React.ReactNode;
  heading: string;
  message: string;
  cancel: string;
  confirm: string;
  handleConfirm: () => void;
  setShowDialog: (show: boolean) => void;
  showDialog: boolean;
  isLoading: boolean;
  iconGradient: string;
}

function DialogueBox({
  icon,
  heading,
  message,
  cancel = "Cancel",
  confirm = "Confirm",
  handleConfirm,
  setShowDialog,
  isLoading,
  iconGradient,
}: DialogueBoxProps) {
  return (
    <>
      <div className="fixed inset-0 z-50 flex h-full w-full items-center justify-center bg-black bg-opacity-20">
        <div className="m-2 w-full max-w-md rounded-md bg-white p-6 shadow-lg">
          <div className="flex items-center justify-center rounded-t-lg p-2">
            <div className={`rounded-full ${iconGradient} p-5`}>{icon}</div>
          </div>
          <h3 className="my-3 text-center text-base font-bold">{heading}</h3>
          <p className="text-center text-sm">{message}</p>
          <div className="mt-4 flex justify-between gap-2">
            <button
              className="flex-1 rounded-md bg-gray-100 px-4 py-2 font-semibold text-gray-600"
              onClick={() => setShowDialog(false)}
            >
              {cancel}
            </button>
            <button
              className="flex-1 rounded-md bg-red-600 px-4 py-2 text-white"
              onClick={handleConfirm}
              disabled={isLoading}
            >
              {isLoading ? (
                <ClipLoader color="#ffffff" size={18}></ClipLoader>
              ) : (
                confirm
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default DialogueBox;
