import React, { useState, useEffect } from "react";
import { ClipLoader } from "react-spinners";
import OrderAddresses from "../../../shared/components/order-address/OrderAddress";
import OrderProducts from "../../../shared/components/order-products/OrderProducts";
import { useTranslation } from "react-i18next";
import helperService from "../../../services/helperService";
import { toast } from "react-toastify";
import apiService from "../../../services/apiService";
import { useSelector } from "react-redux";

interface Address {
  address_id: number;
  address_line1: string;
  address_line2?: string; // Optional field if not always present
  city: string;
  country: string;
  created_at: string;
  default_billing: boolean;
  default_shipping: boolean;
  deleted_at?: string | null; // Could be null
  name: string;
  pincode: string;
  state: string;
  updated_at: string;
  user_id: number;
}

const CartReview: React.FC = () => {
  const [loadingOrderInfo, setLoadingOrderInfo] = useState<boolean>(false);
  const [showPrice, setShowPrice] = useState<boolean>(false);
  const { t } = useTranslation();
  const [billingAddress, setBillingAddress] = useState<Address | null>(null);
  const [shippingAddress, setShippingAddress] = useState<Address | null>(null);
  const [products, setProducts] = useState<any[]>([]);
  const { config } = useSelector((state: any) => state.config);

  // Order Details on component mount
  useEffect(() => {
    setLoadingOrderInfo(true);
    if (Object.keys(config).length !== 0) {
      setShowPrice(helperService.checkConfigByKey(config, "show_price"));
      getCartDetails();
    }
  }, [config]);

  //call api to fetch particular cart details and send data to child component
  const getCartDetails = async () => {
    setLoadingOrderInfo(true);
    try {
      const res: any = await apiService.getUserCart();
      setBillingAddress(res.data.cart.billing_address);
      setShippingAddress(res.data.cart.shipping_address);
      setProducts(res.data.cart.cart_items);
    } catch (error) {
      const errorMessage = helperService.extractErrorMessage(error);
      toast.error(errorMessage);
    } finally {
      setLoadingOrderInfo(false);
    }
  };

  return (
    <div className="flex h-full flex-col">
      <div className="h-3/4 w-full md:p-4">
        {loadingOrderInfo ? (
          <div
            className="flex items-center justify-center pt-64"
            data-testid="clip-loader"
          >
            <ClipLoader color="#0000ff" loading={loadingOrderInfo} size={50} />
          </div>
        ) : (
          <>
            <div className="flex items-center justify-start gap-2">
              <h3 className="p-2 text-xl font-semibold">
                {t("cart.ORDER_REVIEW")}
              </h3>
            </div>
            <div className="flex h-full flex-col">
              <div className="mt-2">
                {/* Products/items details of particular order */}
                <OrderProducts products={products} showPrice={showPrice} />
              </div>
              <div className="space-y-8">
                {/* Billing Address of particular order*/}
                <div className="pt-8">
                  <OrderAddresses
                    title={t("common.BILLING_ADDRESS")}
                    address={billingAddress}
                    backgroundColor="bg-background-grey-100"
                  />
                </div>
                {/* Shipping Address of particular order */}
                <OrderAddresses
                  title={t("common.SHIPPING_ADDRESS")}
                  address={shippingAddress}
                  backgroundColor="bg-background-grey-100"
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CartReview;
