import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import breadcrumbReducer from "./slices/breadcrumSlice";
import cartReducer from "./slices/cartSlice";
import configReducer from "./slices/configSlice";
import countriesReducer from "./slices/countriesSlice";

const store = configureStore({
  reducer: {
    breadcrumb: breadcrumbReducer,
    cart: cartReducer,
    config: configReducer,
    countries: countriesReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
