import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { changePassword } from "../../constants/validationSchemas";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import apiService from "../../../services/apiService";
import { toast } from "react-toastify";
import helperService from "../../../services/helperService";
import { ClipLoader } from "react-spinners";

interface ChangePasswordDialogProps {
  isOpen: boolean;
  setIsOpen: any;
}

const ChangePasswordDialog: FC<ChangePasswordDialogProps> = ({
  isOpen,
  setIsOpen,
}) => {
  const [success, setsuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(changePassword),
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
  });

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    try {
      const payload = {
        current_password: data.currentPassword,
        new_password: data.newPassword,
        confirm_password: data.confirmPassword,
      };
      const res = await apiService.changePassword(payload);
      toast.success(helperService.extractSuccessMessage(res));
      setsuccess(true);
    } catch (error) {
      toast.error(helperService.extractErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  };

  if (!isOpen) return null;

  if (success) {
    return (
      <div className="m-2">
        <div className="fixed inset-0 flex h-full w-full items-center justify-center bg-black bg-opacity-20">
          <div className="w-full max-w-md rounded-ml bg-white px-6 py-8 shadow-lg">
            <div className="mb-4 flex items-center justify-center">
              <div className="rounded-full bg-blue-100 p-6">
                <CheckIcon className="h-8 w-8 text-blue-700"></CheckIcon>
              </div>
            </div>
            <h3 className="mb-4 text-center text-base">
              {t("my_account.password_change.TITLE")}
            </h3>
            <p className="mb-6 text-center text-xs">
              {t("my_account.password_change.DESCRIPTION")}
            </p>
            <button
              onClick={() => setIsOpen(false)}
              className="w-full rounded-sm bg-primary py-2 text-white"
            >
              {t("my_account.password_change.BUTTON")}
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 z-20 flex h-full w-full items-center justify-center bg-black bg-opacity-20">
      <div className="w-full max-w-md rounded-ml bg-white px-6 py-8 shadow-lg">
        <div className="mb-4 flex items-center justify-between">
          <h2 className="items-center text-xl font-bold">
            {t("common.CHANGE_PASSWORD")}
          </h2>
          <button
            onClick={() => setIsOpen(false)}
            className="rounded-full bg-background-grey-500 p-1 text-gray-500 hover:text-gray-700"
          >
            <XMarkIcon className="h-4 w-4 text-white" />
          </button>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4">
            <label className="text-bases bloc font-medium">
              {t("user_info.CURRENT_PASSWORD")}
            </label>
            <input
              type="password"
              {...register("currentPassword")}
              className="w-full rounded-md border p-2"
              placeholder={t("user_info.CURRENT_PASSWORD")}
            />
            <p className="text-red-600">{errors.currentPassword?.message}</p>
          </div>

          <div className="mb-4">
            <label className="block text-base font-medium">
              {t("user_info.NEW_PASSWORD")}
            </label>
            <input
              type="password"
              {...register("newPassword")}
              className="w-full rounded-md border p-2"
              placeholder={t("user_info.NEW_PASSWORD")}
            />
            <p className="text-red-600">{errors.newPassword?.message}</p>
          </div>

          <div className="mb-4">
            <label className="text-bases bloc font-medium">
              {t("user_info.CONFIRM_PASSWORD")}
            </label>
            <input
              type="password"
              {...register("confirmPassword")}
              className="w-full rounded-md border p-2"
              placeholder={t("place_holders.CONFIRM_PASSWORD")}
            />
            <p className="text-red-600">{errors.confirmPassword?.message}</p>
          </div>

          <div className="flex items-center justify-center">
            <button
              type="submit"
              className="min-w-[300px] rounded-md bg-primary px-10 py-3 text-sm text-white"
              disabled={isLoading}
            >
              {isLoading ? (
                <ClipLoader size={18} color="#ffffff"></ClipLoader>
              ) : (
                t("common.CHANGE_PASSWORD")
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangePasswordDialog;
