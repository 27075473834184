import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const countries = createSlice({
  name: "countries",
  initialState: {
    countries: {} as any,
  },

  reducers: {
    updateCountries: (state, action: PayloadAction<any>) => {
      state.countries = action.payload;
    },
  },
});

export const { updateCountries } = countries.actions;

export default countries.reducer;
