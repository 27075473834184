import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  MapIcon,
  ShoppingBagIcon,
  ShoppingCartIcon,
  QueueListIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  ArrowLeftStartOnRectangleIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";
import { resetBreadcrumb } from "../../redux/slices/breadcrumSlice";
import { updateCartLength } from "../../redux/slices/cartSlice";
import apiService from "../../services/apiService";
import DialogueBox from "../../shared/components/dialogue-box/DialogueBox";
import helperService from "../../services/helperService";
import { toast } from "react-toastify";

const Sidebar: React.FC = () => {
  const { t } = useTranslation();
  const isLogin = localStorage.getItem("accessToken");
  const isUserApproved = localStorage.getItem("isUserApproved");

  const menuItems = [
    {
      label: t("common.PRODUCTS"),
      path: "/category/root",
      icon: <QueueListIcon width={20} height={20} />,
    },
    {
      label: t("cart.MY_CART"),
      path: "/cart",
      icon: <ShoppingBagIcon width={20} height={20} />,
    },
    {
      label: t("common.ORDERS"),
      path: "/orders",
      icon: <ShoppingCartIcon width={20} height={20} />,
    },
    {
      label: t("address_management.SAVED_ADDRESS_HEADER"),
      path: "/address",
      icon: <MapIcon width={20} height={20} />,
    },
  ];

  const [menuItemsSecondary, setMenuItemsSecondary] = useState([
    {
      label: t("common.CONTACT_US"),
      path: "/contact-us",
      icon: <ChatBubbleOvalLeftEllipsisIcon width={20} height={20} />,
    },
    {
      label: t("common.LOGOUT"),
      path: "/sign-in",
      icon: <ArrowLeftStartOnRectangleIcon width={20} height={20} />,
    },
  ]);

  useEffect(() => {
    // Check if token is not found in localStorage
    const token = localStorage.getItem("accessToken");
    if (!token) {
      // Remove object with label t("common.LOGOUT")
      setMenuItemsSecondary((prevItems) =>
        prevItems.filter((item) => item.label !== t("common.LOGOUT")),
      );
    }
  }, []);

  const [isOpen, setIsOpen] = useState(true);
  const [selectedItem, setSelectedItem] = useState<string | null>(
    t("common.PRODUCTS"),
  );
  const [windowWidth, setWindowWidth] = useState(window.innerWidth); // State for window width
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();
  const { cartLength } = useSelector((state: any) => state.cart);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const [isLoading, setIsLoading] = useState(false);
  const [showLogoutDialog, setShowLogoutDialog] = useState(false);
  const handleLogout = async () => {
    setIsLoading(true);
    try {
      const res = await apiService.logout();
      helperService.clearLocalStorage();
      setShowLogoutDialog(false);
      toast.success(helperService.extractSuccessMessage(res));
      window.location.replace("/sign-in");
    } catch (error) {
      toast.error(helperService.extractErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  };

  const handleMenuItemClick = (item: { label: string; path: string }) => {
    if (item.label?.toString() === t("common.LOGOUT")) {
      setShowLogoutDialog(true);
    } else {
      setSelectedItem(item.label);
      handleRootCategoryNav(item.path);
      navigate(item.path);
    }
  };

  const handleRootCategoryNav = (path: string) => {
    if (path === "/category/root") {
      dispatch(resetBreadcrumb());
    }
  };

  async function getUserCart() {
    if (isLogin && isUserApproved === "true") {
      try {
        const res: any = await apiService.getUserCart();
        dispatch(updateCartLength({ length: res.data.cart.cart_items.length }));
      } catch (error) {
        console.error("Error fetching user cart:", error);
      }
    } else {
      return 0;
    }
  }

  useEffect(() => {
    setShowLogoutDialog(false);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    getUserCart();
    const currentPath = location.pathname;
    const currentItem =
      menuItems.find((item) => item.path === currentPath) ||
      menuItemsSecondary.find((item) => item.path === currentPath);

    if (
      currentPath.startsWith("/category/") ||
      currentPath.startsWith("/product/")
    ) {
      setSelectedItem(t("common.PRODUCTS"));
    } else if (currentPath.includes("order")) {
      setSelectedItem(t("common.ORDERS"));
    } else if (currentItem) {
      setSelectedItem(currentItem.label);
    } else {
      setSelectedItem(null);
    }
  }, [location]);

  useEffect(() => {
    if (windowWidth < 900) setIsOpen(false);
    else setIsOpen(true);
  }, [windowWidth]);

  return (
    <div className="relative min-h-full max-md:min-w-[54px]">
      <div
        className={`z-20 min-h-full bg-white shadow-md max-md:absolute max-md:left-0 max-md:top-0 ${
          isOpen ? "w-64" : "sidebar-close"
        } transition-width flex flex-col duration-300`}
      >
        <div className="flex items-center justify-between p-4">
          {isOpen && (
            <h1 className="sm:text-l text-dark-blue font-bold transition-opacity duration-300 md:text-2xl lg:text-2xl">
              <Link to={"/"}>{t("common.PRODUCT_TITLE")}</Link>
            </h1>
          )}
          {isOpen ? (
            <span className="cursor-pointer rounded-lg p-1 hover:bg-gray-100">
              <ChevronLeftIcon width={20} height={20} onClick={toggleSidebar} />
            </span>
          ) : (
            <span className="cursor-pointer rounded-lg p-1 hover:bg-gray-100">
              <ChevronRightIcon
                width={20}
                height={20}
                onClick={toggleSidebar}
              />
            </span>
          )}
        </div>
        {isOpen ? <hr className="mt-3" /> : <hr className="mt-6" />}

        <div className="mt-6 flex-grow">
          <ul>
            {menuItems.map((menuItem) => (
              <li
                key={menuItem.label}
                className={`flex items-center px-4 py-2 text-gray-600 ${
                  selectedItem === menuItem.label
                    ? "bg-blue-100"
                    : "hover:bg-gray-100"
                } cursor-pointer rounded-sm ${isOpen ? "mx-2 mb-2" : ""}`}
                onClick={() => handleMenuItemClick(menuItem)}
              >
                <span
                  className={`${
                    menuItem.label === selectedItem ? "border text-primary" : ""
                  }`}
                >
                  {menuItem.icon}
                </span>

                <div className="relative flex w-full items-center justify-between">
                  <span
                    className={`ml-4 font-semibold transition-all duration-300 ${
                      !isOpen && "hidden opacity-0"
                    } ${selectedItem === menuItem.label ? "text-blue-500" : ""}`}
                  >
                    {menuItem.label}
                  </span>
                  {menuItem.label === "Cart" && cartLength ? (
                    <span
                      className={`${
                        isOpen
                          ? "rounded-sm border bg-primary px-2 text-white"
                          : "absolute left-[-1.8rem] top-[-1.2rem] rounded-full bg-primary p-[2px] text-xs text-white"
                      } `}
                    >
                      {cartLength}
                    </span>
                  ) : null}
                </div>
              </li>
            ))}
          </ul>
        </div>

        <div className="mb-4 mt-auto">
          <hr className="my-4" />
          <ul>
            {menuItemsSecondary.map((menuItem) => (
              <li
                key={menuItem.label}
                className={`flex items-center px-4 py-2 text-gray-600 ${
                  selectedItem === menuItem.label
                    ? "bg-blue-100"
                    : "hover:bg-gray-100"
                } cursor-pointer rounded-sm ${isOpen ? "mx-2 mb-2" : ""}`}
                onClick={() => handleMenuItemClick(menuItem)}
              >
                {menuItem.icon}
                <span
                  className={`ml-4 font-semibold transition-all duration-300 ${
                    !isOpen && "hidden opacity-0"
                  } ${selectedItem === menuItem.label ? "text-blue-500" : ""}`}
                >
                  {menuItem.label}
                </span>
              </li>
            ))}
          </ul>
        </div>

        {showLogoutDialog && (
          <DialogueBox
            icon={
              <ArrowLeftStartOnRectangleIcon
                width={40}
                height={40}
                className="text-error"
              />
            }
            heading={t("common.LOGOUT_CONFIRMATION_TITLE")}
            message={t("common.LOGOUT_CONFIRMATION_MESSAGE")}
            cancel={t("common.CANCEL")}
            confirm={t("common.LOGOUT")}
            handleConfirm={handleLogout}
            setShowDialog={setShowLogoutDialog}
            isLoading={isLoading}
            iconGradient="bg-error/10"
            showDialog={showLogoutDialog}
          />
        )}
      </div>
    </div>
  );
};

export default Sidebar;
