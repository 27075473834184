import moment from "moment";

const helperService = {
  removeEmptyKeys: (obj: any): any => {
    // If the input is not an object, return it as is (base case for recursion)
    if (typeof obj !== "object" || obj === null) {
      return obj;
    }

    // Iterate over the keys of the object
    Object.keys(obj).forEach((key) => {
      const value = obj[key];

      // If the value is an empty string, delete the key
      if (value === "") {
        delete obj[key];
      }
      // If the value is an object, recursively clean the nested object
      else if (typeof value === "object" && value !== null) {
        helperService.removeEmptyKeys(value);

        // After cleaning the nested object, check if it's empty and remove it
        if (Object.keys(value).length === 0) {
          delete obj[key];
        }
      }
    });

    return obj;
  },

  // Add the reusable handleBreadcrumbClick function
  handleBreadcrumbClick: (item: any, navigate: any) => {
    const categoryName = item.name;
    const category_id = item.category_id;
    if (categoryName && category_id && category_id !== "root") {
      navigate(`/category/${categoryName}/${category_id}`);
    } else {
      navigate("/category/root");
    }
  },

  extractSuccessMessage: (response: any): string => {
    return (
      response.message || response.data?.message || "Operation successful."
    );
  },

  extractErrorMessage: (error: any): string => {
    return (
      error.response?.data?.message || error.message || "Operation failed."
    );
  },

  formatDate: (dateString: string) => {
    return moment(dateString).format("MM/DD/YYYY | hh:mm A");
  },

  checkConfigByKey: (config: any, configKey: string) => {
    if (config.is_active === false) {
      return true;
    } else {
      const configItem = config.config_list.find(
        (item: { config_key: string }) => item.config_key === configKey,
      );
      return configItem ? configItem.config_value : false;
    }
  },

  // Function to get the color class based on the order status
  getStatusColor: (status: string): string => {
    const lowerStatus = status.toLowerCase();

    // Determine the color based on the status category
    if (
      lowerStatus === "delivered" ||
      lowerStatus === "payment received" ||
      lowerStatus === "done"
    ) {
      return "text-green-500";
    } else if (
      lowerStatus === "processing" ||
      lowerStatus === "under dispatch" ||
      lowerStatus === "quote sent" ||
      lowerStatus === "shipped" ||
      lowerStatus === "re-invoiced" ||
      lowerStatus === "challan/shipped" ||
      lowerStatus === "merged"
    ) {
      return "text-warning-300";
    } else if (
      lowerStatus === "cancelled" ||
      lowerStatus === "failed" ||
      lowerStatus === "skipped"
    ) {
      return "text-error-300";
    }

    // Default color if status is not matched
    return "text-warning-300";
  },

  clearLocalStorage: () => {
    localStorage.clear();
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("lastPath");
    localStorage.removeItem("isUserApproved");
    sessionStorage.clear();
  },
};

export default helperService;
