import * as Yup from "yup";
import { REJEX_CONSTANTS } from "./globalConstants";

export const registerValidationSchema = Yup.object({
  // User details section
  first_name: Yup.string()
    .trim()
    .required("First name is required")
    .matches(new RegExp(REJEX_CONSTANTS.NAME_PATTERN), "Invalid name format"),
  last_name: Yup.string()
    .trim()
    .required("Last name is required")
    .matches(new RegExp(REJEX_CONSTANTS.NAME_PATTERN), "Invalid name format"),
  email: Yup.string()
    .trim()
    .required("Email is required")
    .matches(new RegExp(REJEX_CONSTANTS.EMAIL_PATTERN), "Invalid email format"),
  mobile_number: Yup.string()
    .trim()
    .nullable()
    .optional()
    .test(
      "len",
      "Mobile number must be exactly 10 digits",
      (value) => !value || (value.length === 10 && /^[0-9]{10}$/.test(value)),
    ),

  password: Yup.string()
    .trim()
    .required("Password is required")
    .matches(
      REJEX_CONSTANTS.PASSWORD_PATTERN,
      "Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one digit, and one special character(@, $, !, %, *, ?, or &).",
    ),
  confirm_password: Yup.string()
    .trim()
    .required("Confirm password is required")

    .oneOf([Yup.ref("password")], "Passwords must match")
    .required("Please confirm your password"),
  user_type: Yup.string()
    .trim()
    .required("User type is required")
    .oneOf(["dealer"], "User type must be dealer"),
  refresh_token: Yup.string().trim().optional(),
  aadhar_number: Yup.string()
    .trim()
    .nullable()
    .optional()
    .test(
      "len",
      "Aadhar number must be exactly 12 digits",
      (value) => !value || (value.length === 12 && /^[0-9]{12}$/.test(value)),
    ),

  // Company details section
  approval_status: Yup.string()
    .trim()
    .required("Approval status is required")
    .oneOf(
      ["approved", "pending", "rejected"],
      "Approval status must be approved, pending, or rejected",
    ),
  company_name: Yup.string().trim().required("Company name is required"),
  address: Yup.string().trim().required("Address is required"),
  city: Yup.string()
    .trim()
    .required("City is required")
    .matches(/^[a-zA-Z\s]+$/, "City can only contain letters and spaces"),
  pincode: Yup.string()
    .trim()
    .required("Pincode is required")
    .matches(/^\d{3,10}$/, "Pincode must be between 3 and 10 digits"),
  state: Yup.string()
    .trim()
    .required("State is required")
    .matches(/^[a-zA-Z\s]+$/, "State can only contain letters and spaces"),
  country: Yup.string()
    .trim()
    .required("Country is required")
    .matches(/^[a-zA-Z\s]+$/, "Country can only contain letters and spaces"),
  gst_number: Yup.string().trim().optional(),
  drug_licence_number: Yup.string().trim().optional(),
  phone_number: Yup.string()
    .trim()
    .required("Phone number is required")
    .matches(
      /^[0-9]{10}$/,
      "Phone number must be exactly 10 digits and contain only numbers between 0 and 9",
    ),
  business_type: Yup.string()
    .trim()
    .required("Business type is required")
    .oneOf(
      ["domestic", "international"],
      "Business typw must be domestic or international",
    ),
  termsAccepted: Yup.boolean()
    .oneOf([true], "Please accept the terms and conditions to continue")
    .required("Please accept the terms and conditions to continue"),
});

export const loginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .email("Invalid email format")
    .required("Email is required"),
  password: Yup.string()
    .trim()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters"),
});

export const emailValidationSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .email("Invalid email format")
    .required("Email is required"),
});

export const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .trim()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long")
    .max(20, "Password cannot exceed 20 characters")
    .matches(/[a-zA-Z]/, "Password can only contain Latin letters.")
    .matches(/[0-9]/, "Password must contain at least one number.")
    .matches(
      /[!@#$%^&*]/,
      "Password must contain at least one special character.",
    ),

  confirm_password: Yup.string()
    .trim()
    .oneOf([Yup.ref("password")], "Passwords must match")
    .required("Confirm password is required"),
});

// Validation schema for the form
export const AddAddressSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .matches(/^(?=.*\S).+$/, "Name cannot be empty or whitespace")
    .matches(
      new RegExp(REJEX_CONSTANTS.NAME_PATTERN),
      "Name cannot contain numbers or special characters",
    ),

  address_line1: Yup.string()
    .required("Address Line 1 is required")
    .matches(/^(?=.*\S).+$/, "Address Line 1 cannot be empty or whitespace")
    .matches(
      /^[a-zA-Z0-9\s,.-]*$/,
      "Address Line 1 can only contain letters, numbers, spaces, commas, periods, and hyphens",
    ),

  address_line2: Yup.string()
    .matches(
      /^(?!\s)(?!.*\s{2,}).*(?<!\s)$/,
      "Address Line 2 cannot start or end with spaces, and cannot contain consecutive spaces.",
    )
    .matches(
      /^[a-zA-Z0-9,.\s-]*$/,
      "Address Line 2 can only include letters, numbers, spaces, commas, periods, and hyphens.",
    ),

  city: Yup.string()
    .required("City is required")
    .matches(/^(?=.*\S).+$/, "City cannot be empty or whitespace")
    .matches(
      /^[a-zA-Z\s]*$/,
      "City cannot contain numbers or special characters",
    ),

  pincode: Yup.string()
    .required("Pincode is required")
    .matches(
      /^(?=.*\S).+$/,
      "Pincode cannot be empty or consist only of spaces",
    )
    .matches(
      /^[a-zA-Z0-9]{3,10}$/,
      "Pincode must be 3-10 characters long and contain only letters and numbers",
    ),

  state: Yup.string()
    .required("State is required")
    .matches(/^(?=.*\S).+$/, "State cannot be empty or whitespace")
    .matches(
      /^[a-zA-Z\s]*$/,
      "State cannot contain numbers or special characters",
    ),

  country: Yup.string().required("Country is required"),

  default_billing: Yup.boolean().optional(),
  default_shipping: Yup.boolean().optional(),
});

// Validation schema for the edit form
export const EditAddressSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .matches(/^(?=.*\S).+$/, "Name cannot be empty or whitespace")
    .matches(
      /^[a-zA-Z\s]*$/,
      "Name cannot contain numbers or special characters",
    ),

  address_line1: Yup.string()
    .required("Address Line 1 is required")
    .matches(/^(?=.*\S).+$/, "Address Line 1 cannot be empty or whitespace")
    .matches(
      /^[a-zA-Z0-9\s,.-]*$/,
      "Address Line 1 can only contain letters, numbers, spaces, commas, periods, and hyphens",
    ),

  address_line2: Yup.string()
    .matches(
      /^(?!\s)(?!.*\s{2,}).*(?<!\s)$/,
      "Address Line 2 cannot start or end with spaces, and cannot contain consecutive spaces.",
    )
    .matches(
      /^[a-zA-Z0-9,.\s-]*$/,
      "Address Line 2 can only include letters, numbers, spaces, commas, periods, and hyphens.",
    ),

  city: Yup.string()
    .required("City is required")
    .matches(/^(?=.*\S).+$/, "City cannot be empty or whitespace")
    .matches(
      /^[a-zA-Z\s]*$/,
      "City cannot contain numbers or special characters",
    ),

  pincode: Yup.string()
    .required("Pincode is required")
    .matches(
      /^(?=.*\S).+$/,
      "Pincode cannot be empty or consist only of spaces",
    )
    .matches(
      /^[a-zA-Z0-9]{3,10}$/,
      "Pincode must be 3-10 characters long and contain only letters and numbers",
    ),

  state: Yup.string()
    .required("State is required")
    .matches(/^(?=.*\S).+$/, "State cannot be empty or whitespace")
    .matches(
      /^[a-zA-Z\s]*$/,
      "State cannot contain numbers or special characters",
    ),

  country: Yup.string().required("Country is required"),

  default_billing: Yup.boolean().optional(),
  default_shipping: Yup.boolean().optional(),
});

export const myProfileSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(/^(?=.*\S).+$/, "First name cannot be empty or whitespace")
    .matches(/^[A-Za-z\s]+$/, "First name must contain only letters and spaces")
    .trim()
    .nullable(),

  lastName: Yup.string()
    .matches(/^(?=.*\S).+$/, "Last name cannot be empty or whitespace")
    .matches(/^[A-Za-z\s]+$/, "Last name must contain only letters and spaces")
    .trim()
    .nullable(),

  email: Yup.string()
    .matches(/^(?=.*\S).+$/, "Email cannot be empty or whitespace")
    .matches(new RegExp(REJEX_CONSTANTS.EMAIL_PATTERN), "Invalid email address")
    .trim()
    .nullable(),

  mobile: Yup.string()
    .matches(/^(?=.*\S).+$/, "Mobile number cannot be empty or whitespace")
    .matches(/^[0-9]+$/, "Mobile number must be digits")
    .length(10, "Mobile number must be exactly 10 digits")
    .nullable(),

  aadhar: Yup.string().matches(
    REJEX_CONSTANTS.AADHAAR_NUMBER,
    "Aadhaar number is not valid",
  ),

  companyName: Yup.string()
    .matches(/^(?=.*\S).+$/, "Company name cannot be empty or whitespace")
    .matches(
      new RegExp(REJEX_CONSTANTS.NAME_PATTERN),
      "Please enter a valid name",
    )
    .trim()
    .nullable(),

  gstNumber: Yup.string().matches(
    /^(?! )(?:\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}|)$/,
    "Invalid GST Number format",
  ),

  drugLicence: Yup.string().matches(
    REJEX_CONSTANTS.STRING,
    "Special characters are not allowed",
  ),

  phoneNumber: Yup.string().matches(
    REJEX_CONSTANTS.PHONE_NUMBER,
    "Mobile number must be digits",
  ),
});

export const contactValidation = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .matches(REJEX_CONSTANTS.WHITE_SPACE, "White space is not allowed")
    .matches(/^(?=.*\S).+$/, "Name cannot be empty or whitespace")
    .matches(
      /^[a-zA-Z\s]*$/,
      "Name cannot contain numbers or special characters",
    ),

  email: Yup.string()
    .required("Email is required")
    .matches(REJEX_CONSTANTS.WHITE_SPACE, "White space is not allowed")
    .matches(/^(?=.*\S).+$/, "Email cannot be empty or whitespace")
    .matches(
      new RegExp(REJEX_CONSTANTS.EMAIL_PATTERN),
      "Invalid email address",
    ),

  companyName: Yup.string()
    .matches(REJEX_CONSTANTS.WHITE_SPACE, "White space is not allowed")
    .matches(
      /^[a-zA-Z\s]*$/,
      "Company Name cannot contain numbers or special characters",
    ),

  phoneNumber: Yup.string()
    .nullable()
    .notRequired()
    .matches(REJEX_CONSTANTS.WHITE_SPACE, "White space is not allowed")
    .matches(/^\d*$/, "Phone number must contain only numbers")
    .test(
      "valid-length",
      "Phone number must be exactly 10 digits",
      (value) => !value || value.length === 10,
    ),

  subject: Yup.string()
    .required("Subject is required")
    .matches(REJEX_CONSTANTS.WHITE_SPACE, "White space is not allowed")
    .matches(
      /^[a-zA-Z0-9][a-zA-Z0-9 .,!?;:'"-]*[a-zA-Z0-9]?$/,
      "Only letters, numbers, spaces, and punctuation marks (.,!?;:'\"-) are allowed, and it cannot contain leading or trailing spaces.",
    ),

  message: Yup.string()
    .required("Message is required")
    .matches(
      /^[a-zA-Z0-9][a-zA-Z0-9 .,!?;:'"-]*[a-zA-Z0-9]?$/,
      "Only letters, numbers, spaces, and punctuation marks (.,!?;:'\"-) are allowed, and it cannot contain leading or trailing spaces.",
    ),
});

export const resetPassword = Yup.object().shape({
  password: Yup.string()
    .trim()
    .required("Password is required")
    .matches(
      REJEX_CONSTANTS.PASSWORD_PATTERN,
      "Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one digit, and one special character(@, $, !, %, *, ?, or &).",
    ),
  confirmPassword: Yup.string()
    .trim()
    .required("Confirm password is required")
    .oneOf([Yup.ref("password")], "Passwords must match")
    .required("Please confirm your password"),
});

export const changePassword = Yup.object().shape({
  currentPassword: Yup.string()
    .trim()
    .required("Current password is required")
    .matches(
      REJEX_CONSTANTS.PASSWORD_PATTERN,
      "Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one digit, and one special character(@, $, !, %, *, ?, or &).",
    ),

  newPassword: Yup.string()
    .trim()
    .required("New password is required")
    .matches(
      REJEX_CONSTANTS.PASSWORD_PATTERN,
      "Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one digit, and one special character(@, $, !, %, *, ?, or &).",
    )
    .notOneOf(
      [Yup.ref("currentPassword")],
      "New password cannot be the same as the current password",
    ),

  confirmPassword: Yup.string()
    .trim()
    .required("Confirm password is required")
    .oneOf([Yup.ref("newPassword")], "Passwords must match"),
});
