import React from "react";
import { useTranslation } from "react-i18next";

interface OrderProductsProps {
  products: any[];
  showPrice?: boolean;
}

const OrderProducts: React.FC<OrderProductsProps> = ({
  products,
  showPrice,
}) => {
  const { t } = useTranslation();

  return (
    <div className="max-h-[25rem] max-w-[calc(100vw-5.5rem)] flex-grow overflow-x-scroll overflow-y-scroll">
      <table
        data-testId="cart-table-render"
        className="w-full border-collapse rounded-lg border border-gray-300"
      >
        <thead>
          <tr className="bg-[#EDEDED] text-left">
            <th className="border border-gray-300 px-2 py-2">#</th>
            <th className="border border-gray-300 px-2 py-2">
              {t("cart.CATEGORY_NUMBER")}
            </th>
            <th className="border border-gray-300 px-2 py-2">
              {t("cart.TYPE")}
            </th>
            <th className="border border-gray-300 px-2 py-2">
              {t("cart.PRODUCT_NAME")}
            </th>
            <th className="border border-gray-300 px-2 py-2">
              {t("cart.PENDING")}
            </th>
            {showPrice && (
              <th className="border border-gray-300 px-2 py-2">
                  {t("common.CURRENCY_NAME")}
              </th>
            )}
            <th className="border border-gray-300 px-2 py-2">
              {t("cart.QUANTITY")}
            </th>
            {showPrice && (
              <th className="border border-gray-300 px-2 py-2">
                {t("cart.TOTAL")}
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {products.map((item, index) => (
            <tr key={index} className="bg-offwhite">
              <td className="border border-gray-300 px-2 py-1">{index + 1}</td>
              <td className="border border-gray-300 px-2 py-1">
                {item.catalogue_no}
              </td>
              <td className="border border-gray-300 px-2 py-1">
                {item.product_type}
              </td>
              <td className="border border-gray-300 px-2 py-1">
                {item.product_name}
              </td>
              <td className="border border-gray-300 px-2 py-1">
                {item.pending_quantity}
              </td>
              {showPrice && (
                <td className="border border-gray-300 px-2 py-1">
                  {t("common.CURRENCY_SYMBOL")}
                  {item.price}
                </td>
              )}
              <td className="border border-gray-300 px-2 py-1">
                {item.quantity}
              </td>
              {showPrice && (
                <td className="border border-gray-300 px-2 py-1">
                  {t("common.CURRENCY_SYMBOL")}
                  {item.sub_total}
                </td>
              )}
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr className="bg-primary/20 font-semibold">
            <td
              colSpan={showPrice ? 6 : 5}
              className="border-t border-primary-400 px-2 py-2 text-left"
            >
              {showPrice ? t("cart.QUANTITY_AND_ORDER_TOTAL") : t("cart.QTY")}
            </td>
            <td className="border-t border-primary-400 px-2 py-2">
              {products.reduce((sum, item) => sum + item.quantity, 0)}
            </td>
            {showPrice && (
              <td className="border-t border-primary-400 px-2 py-2">
                {t("common.CURRENCY_SYMBOL")}
                {products.reduce((sum, item) => sum + item.sub_total, 0)}
              </td>
            )}
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default OrderProducts;
