import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";

function EmailVerification() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const message: any = queryParams.get("e");

  const navigateToSignIn = () => {
    navigate("/");
  };
  return (
    <div className="gradient z-50 flex min-h-screen flex-col items-center justify-center lg:flex-row">
      <div className="flex w-full flex-col items-center justify-center p-12">
        <div>
          <h1 className="mb-8 text-2xl font-bold text-white">
            {t("common.PRODUCT_TITLE")}
          </h1>
        </div>
        <div className="max-w rounded-md bg-white p-8 text-center shadow-lg">
          <div className="mb-4 flex items-center justify-center"></div>
          <h2 className="text-blue-color mb-4 text-3xl font-bold">
            {decodeURIComponent(message)}
          </h2>

          <button
            className="button-color w-full rounded p-3 font-semibold text-white"
            onClick={navigateToSignIn}
          >
            {t("email_verification.BUTTON")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default EmailVerification;
