import React from "react";

interface Address {
  name: string;
  address_line1: string;
  address_line2?: string;
  city: string;
  state: string;
  country: string;
  pincode: string;
}

interface OrderAddressesProps {
  title: string;
  address: Address | null;
  backgroundColor?: string;
}

const OrderAddresses: React.FC<OrderAddressesProps> = ({
  title,
  address,
  backgroundColor,
}) => {
  return (
    <div>
      <h3 className="p-2 text-xl font-semibold">
        {title}</h3>
      <div
        className={`rounded-md border border-background-grey-200 p-4 ${backgroundColor}`}
      >
        <h4 className="text-lg font-semibold">
        <span className="inline-block w-1.5 h-1.5 bg-black rounded-full mr-2 mb-1"></span>
          {address?.name}</h4>
        <p
          title={`${address?.address_line1}${
            address?.address_line2 ? `, ${address?.address_line2}` : ""
          }, ${address?.city}, ${address?.state}, ${address?.country} - ${address?.pincode}`}
          className="max-w-[100ch] truncate text-wrap font-bold ml-3"
        >
          {`${address?.address_line1}${
            address?.address_line2 ? `, ${address?.address_line2}` : ""
          }, ${address?.city}, ${address?.state}, ${address?.country} - ${address?.pincode}`}
        </p>
      </div>
    </div>
  );
};

export default OrderAddresses;
