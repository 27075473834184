// src/components/Breadcrumb.tsx
import React from "react";
import { useNavigate } from "react-router-dom";

const Breadcrumb = ({
  breadcrumb,
  onClick,
}: {
  breadcrumb: any[];
  onClick?: (item: any) => void;
}) => {
  const navigate = useNavigate(); // Initialize useNavigate

  // Handle click based on the item
  const handleBreadcrumbClick = (item: any) => {
    if (onClick) {
      // Allow parent component to handle click if passed
      onClick(item);
    } else {
      // Default behavior: Navigate to the item's path
      navigate(item.path);
    }
  };

  return (
    <nav className="mb-2 flex text-sm">
      {breadcrumb.map((item, index) => (
        <span className="flex items-center">
          {index === breadcrumb.length - 1 ? (
            <p className="text-black">{item.name}</p> // Last element with no click event
          ) : (
            <p
              className="cursor-pointer text-blue-500 hover:underline"
              onClick={() => handleBreadcrumbClick(item)}
            >
              {item.name}
            </p>
          )}
          {index < breadcrumb.length - 1 && <span>/</span>}{" "}
          {/* Add spacing around the separator */}
        </span>
      ))}
    </nav>
  );
};

export default Breadcrumb;
