import { useTranslation } from "react-i18next";
import Map from "../../shared/components/map/Map";
import ContactInfo from "../../shared/components/contact-info/ContactInfo";
import ContactUsForm from "../../shared/components/contact-us-form/ContactUsForm";

const ContactUs = () => {
  const { t } = useTranslation();

  return (
    <main className="flex h-full w-full flex-col gap-6 overflow-y-auto pb-4 max-sm:p-4">
      <section className="flex flex-col gap-4 lg:flex-row">
        {/* Contact Form */}
        <section className="w-full flex-1">
          <h1 className="text-2xl font-semibold">{t("contact.CONTACT_US")}</h1>
          <div className="mt-2">{t("contact.DESCRIPTION")}</div>
          <div className="mt-8">
            <ContactUsForm />
          </div>
        </section>
        {/* Map */}
        <Map url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3691.922473864204!2d73.1561886113325!3d22.280926379615828!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395fc8d5e2ca9d9d%3A0x1fb2c5b1a651e694!2sSadhrasya%20SoftTech!5e0!3m2!1sen!2sin!4v1730881333353!5m2!1sen!2sin" />
      </section>
      {/* Contact Details */}
      <ContactInfo />
    </main>
  );
};

export default ContactUs;
