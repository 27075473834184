import React from "react";
import { useTranslation } from "react-i18next";
import { languages } from "../../assets/i18n";

const Footer: React.FC = () => {
  const { t, i18n } = useTranslation();

  // Function to handle language change
  const handleLanguageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedLanguage = e.target.value;
    i18n.changeLanguage(selectedLanguage);
  };

  return (
    <div className="light-blue-bg p-4 text-center">
      <div className="flex flex-col items-center justify-between md:flex-row">
        <p className="text-sm text-gray-600">
          Made with <span className="text-red-500">❤️</span> by
          <span className="ml-1 font-bold text-blue-500">
            {t("common.COMPANY")}
          </span>
        </p>
        <div className="flex items-center gap-2">
          <p className="text-sm text-gray-600">
            All rights reserved to
            <a
              href="https://sadhrasya.com"
              className="ml-1 font-bold text-blue-500"
            >
              {t("common.COMPANY")}
            </a>
          </p>

          <div className="">
            <select
              className="rounded border border-blue-500 p-1"
              onChange={handleLanguageChange}
              value={i18n.language}
            >
              {languages.map((lang) => (
                <option key={lang} value={lang}>
                  {lang.toUpperCase()}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
