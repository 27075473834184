import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateCartLength } from "../../../redux/slices/cartSlice";
interface OrderConfirmationProps {
  message: string;
  orderNo: string;
  dateTime: string;
}

const OrderConfirmation: React.FC<OrderConfirmationProps> = (props) => {
  const dispatch = useDispatch();

  // Dispatch updateCartLength to reset it to zero when the component is initialized
  useEffect(() => {
    dispatch(updateCartLength({ length: 0 }));
  }, [dispatch]);

  return (
    <>
      <div className="flex h-full items-center justify-center bg-gray-100 md:p-24">
        <div className="w-full max-w-md rounded-lg bg-white p-8 shadow-md">
          {/* Success Icon */}
          <div className="mb-6 flex justify-center">
            <div className="flex h-16 w-16 items-center justify-center rounded-full bg-success text-4xl text-white">
              &#10004;
            </div>
          </div>

          {/* Thank You Message */}
          <h1 className="mb-2 text-center text-2xl font-semibold">Thank You</h1>
          <p className="mb-6 text-center text-gray-500">
            Your order is successfully placed
          </p>

          {/* Order Details */}
          <div className="mb-6 border-t border-gray-200 pt-4">
            <div className="mb-2 flex justify-between">
              <span className="text-gray-500">Order Number:</span>
              <span className="font-medium">{props.orderNo}</span>
            </div>
            <div className="flex justify-between">
              <span className="text-gray-500">Date & Time:</span>
              <span className="font-medium">{props.dateTime}</span>
            </div>
          </div>

          {/* Continue Shopping Button */}
          <div className="text-center">
            <Link to={"/"}>
              <button className="button-color mt-4 rounded-md bg-blue-600 px-8 py-3 text-white">
                Continue shopping
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderConfirmation;
