import React from "react";
import { Link } from "react-router-dom";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";

type NoDataPageProps = {
  title: string;
  message: string;
  buttonText: string;
  link: string;
};

const NoDataPage: React.FC<NoDataPageProps> = ({
  title,
  message,
  buttonText,
  link,
}) => {
  return (
    <div className="mt-4 flex h-[40rem] flex-col items-center justify-center rounded-xl border bg-offwhite p-4 text-center">
      <ExclamationCircleIcon
        data-testid="exclamation-icon"
        width={10}
        height={10}
        className="h-20 w-20 stroke-current text-background-grey-500"
      />
      <p className="my-2 mt-4 text-xl font-bold">{title}</p>
      <p className="text-sm">{message}</p>
      <Link
        to={link}
        className="mt-4 rounded-md bg-blue-600 px-4 py-2 text-white"
      >
        {buttonText}
      </Link>
    </div>
  );
};

export default NoDataPage;
