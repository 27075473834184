import { Navigate, Outlet, useLocation } from "react-router-dom";

const ProtectedRoute = () => {
  const location = useLocation();
  const isAuthenticated = localStorage.getItem("accessToken") !== null;

  // If not authenticated, store the current path and redirect to the sign-in page
  if (!isAuthenticated) {
    localStorage.setItem("lastPath", location.pathname);
    return <Navigate to="/sign-in" />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
