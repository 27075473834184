import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "../src/assets/i18n";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

Bugsnag.start({
  // apiKey: process.env.REACT_APP_API_KEY || "",
  apiKey: "afc4c16eec36e3c2c29e89b15def5df5",
  plugins: [new BugsnagPluginReact(React)],
  enabledReleaseStages: ["Dev", "Staging", "Production"],
  releaseStage: "Production",
});

const ErrorBoundary: any =
  Bugsnag?.getPlugin("react")?.createErrorBoundary(React);

root.render(
  <ErrorBoundary>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </ErrorBoundary>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
