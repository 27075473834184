import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function RegisteredSuccess({
  title,
  subtitle,
  description,
  button_label,
  button_link,
  icon,
  bg_color,
}: any) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const navigateToSignIn = () => {
    navigate(button_link || "/");
  };

  return (
    <div className="gradient z-50 flex min-h-screen flex-col items-center justify-center lg:flex-row">
      <div className="flex w-full flex-col items-center justify-center p-12">
        <div>
          <h1 className="mb-8 text-2xl font-bold text-white">
            {title || t("common.PRODUCT_TITLE")}
          </h1>
        </div>
        <div className="max-w rounded-md bg-white p-8 text-center shadow-lg">
          <div className="mb-4 flex items-center justify-center">
            <div
              className={`rounded-full ${bg_color ? bg_color : "bg-success/40"} p-4`}
            >
              {icon}
            </div>
          </div>
          <h2 className="text-blue-color mb-4 text-3xl font-bold">
            {subtitle || t("registration.REGISTERED_SUCCESSFULLY")}
          </h2>
          <p className="mb-6 text-gray-600">
            {description || t("registration.LOGIN_REQUEST")}
          </p>

          <button
            className="button-color w-full rounded p-3 font-semibold text-white"
            onClick={navigateToSignIn}
          >
            {button_label || t("common.LOGIN")}
          </button>
        </div>
      </div>
    </div>
  );
}
